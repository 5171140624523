import React, {useContext, useEffect, useState} from 'react';
import Typography from '@material-ui/core/Typography';
import JobPosting from '../components/jobposting';
import ApplyNowForm from '../components/applynowform';
import Button from '@material-ui/core/Button';
import '../components/css/positions.css';
import {GlobalContext} from "../context/GlobalContext";
import {fetchPositions} from "../context/FetchPositions";
import RefreshIcon from '@material-ui/icons/Refresh';
import {Progress} from "./layout";

const PositionsList = ({ positionData, applySlug, showOption }) => {
    const [ applyNowPosition, setApplyNowPosition ] = useState(null);
    const { setMessageDialog, setInProgress, setPositionData, inProgress, setFullInProgress } = useContext(GlobalContext);

    // do we have an apply direct link?
    const showApplyNow = applySlug && showOption === 'applynow';

    useEffect(() => {
        if (!positionData || !showApplyNow) return;

        const showApplyPosition = positionData.positions.filter( (p) => p.slug === applySlug ).at(0);
        if (showApplyPosition) {
            setApplyNowPosition( showApplyPosition );
        }
    }, [positionData, applySlug, showApplyNow]);

    if (!positionData || inProgress) return (Progress);


    if (!applySlug && positionData.positions.length === 0) {
        return (<div>
            <Typography align="center" variant="h2" component="h6">Error Loading Current Positions</Typography>

            <div className="refresh-wrapper" style={{textAlign:'center'}}>
                <Button onClick={
                    () => {
                        setInProgress(true);

                        fetchPositions()
                            .then((newPositionData) => setPositionData(newPositionData))
                            .then( () => setInProgress(false) );
                    }
                } style={{ fontSize:'1.5em', margin:'1em' }}><RefreshIcon />&nbsp;&nbsp;Try Again</Button>
            </div>
        </div>);
    }

    return (<>
        <div className="refresh-wrapper">
            <Button onClick={
                () => {
                    setInProgress(true);

                    fetchPositions()
                        .then( (newPositionData) => setPositionData(newPositionData) )
                        .then( () => setInProgress(false) );
                }
            }><RefreshIcon />&nbsp;&nbsp;Refresh List</Button>
        </div>

        {applyNowPosition ? (
            <ApplyNowForm
                position={applyNowPosition}
                school={positionData.schools.filter( (s) => s.id === applyNowPosition.schoolId ).at(0)}
                setError={(error) => setMessageDialog({severity:'error', message:error})}
                close={(success) => {
                    if (success) setMessageDialog({severity:'success', message:success});
                    setApplyNowPosition(null);
                }}
                onInProgress={() => setFullInProgress(true)}
                offInProgress={() => setFullInProgress(false)}
            />
        ) : null}

        <div>
            {positionData.positions.filter( (p) => !applySlug || p.slug === applySlug ).map( (p) => (
                <JobPosting
                    key={p.id}
                    applyNowForm={() => setApplyNowPosition(p)}
                    position={p}
                    school={positionData.schools.filter( (s) => s.id === p.schoolId ).at(0)}
                />
            ))}
        </div>
    </>);
}

export default PositionsList;
