import React, {useContext} from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Divider from '../components/divider';
import Typography from '@material-ui/core/Typography';
import '../components/css/positions.css';
import PositionList from "../components/PositionList";
import {GlobalContext} from "../context/GlobalContext";

const PositionsPage = ({ location }) => {
  const {positionData} = useContext(GlobalContext);
  const urlParams = new URLSearchParams(location.search);

  return (
    <Layout>
      <SEO title="Positions" description="Find community and technical college senior academic administrator jobs - executive director, president, vice president, dean." />
      <div className="positions -container">
        <div className="positions -header">
          <h1>Positions</h1>
          <Divider alt="Decorative divider line" />
          <Typography variant="h6" component="p" style={{marginTop:'1em'}}>Find community and technical college senior academic administrator jobs.</Typography>
        </div>

        <PositionList
            positionData={positionData}
            applySlug={urlParams.get('apply')}
            showOption={urlParams.get('show')}
        />
      </div>
    </Layout>
  );
}

export default PositionsPage;
